var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mx-auto my-12 rounded-xl", attrs: { "max-width": "460" } },
    [
      _c("v-img", { attrs: { height: "250", src: _vm.imageUrl } }),
      _c("v-card-title", { staticStyle: { "word-break": "break-word" } }, [
        _c(
          "a",
          { staticClass: "card-title", attrs: { href: "/blog/" + _vm.slug } },
          [_vm._v(_vm._s(_vm.title))]
        ),
      ]),
      _c("v-card-text", [
        _c("div", { staticClass: "card-description" }, [
          _vm._v(" " + _vm._s(_vm.shortDescription) + " "),
        ]),
      ]),
      _c("v-card-text", [
        _c("div", { staticClass: "card-date" }, [
          _vm._v(_vm._s(_vm.publishedAt)),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }