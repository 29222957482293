<template>
  <v-container class="mx-0 mx-md-auto col-12 col-md-11 pl-0 pr-0 pt-10">
    <v-row>
      <v-col class="col-md-12">
        <div class="top_box d-flex flex-column justify-center text-center">
          <h1 style="font-family: Merriweather !important; color: white;">
            Car Advice, Articles, DIY Auto Repair and More
          </h1>
        </div>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-progress-linear
        :size="70"
        :width="10"
        color="#12945f"
        indeterminate
        v-show="loading"
      ></v-progress-linear>
    </v-row>
    <v-row class="mr-5 ml-5 mt-0 mr-md-15 pl-md-15 pr-md-15">
      <v-col class="col-md-4" v-for="blogItem in blogItems" :key="blogItem.id">
        <blog-item
          :image-url="baseUrl+blogItem.Picture.url"
          :title="blogItem.Title"
          :slug="blogItem.Slug"
          :short-description="blogItem.shortDescription"
          :published-at="moment(new Date(blogItem.publishedAt).getFullYear() > 2000 ? blogItem.publishedAt : blogItem.updatedAt).format('ll')"
        ></blog-item>
      </v-col>
    </v-row>
    <v-row justify="center" class="mb-15">
      <v-col cols="8">
        <v-pagination
          v-model="page"
          :length="length"
          :total-visible="7"
          circle
        ></v-pagination>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Component, Vue, Watch } from 'vue-property-decorator';
import BlogItem from '@/components/blog/BlogItem.vue';
import httpClient from '@/api/config/strapiHttpClient';
import webConstant from '@/api/config/webConstant';
import moment from 'moment';

@Component({
  components: { BlogItem },
})
export default class Blog extends Vue {
  page;

  length;

  blogItems;

  baseUrl;

  moment;

  loading;

  constructor() {
    super();
    this.page = 1;
    this.length = 0;
    this.loading = true;
    this.blogItems = [];
    this.baseUrl = webConstant.STRAPI_BASE_URL;
    this.moment = moment;
    this.loadPosts();
  }

  // // eslint-disable-next-line class-methods-use-this
  // async getPosts() {
  //   const res = await httpClient.get('articles/count');
  //   const query = qs.stringify({
  //     _start: 0,
  //     _limit: 9,
  //   }, {
  //     encodeValuesOnly: true,
  //   });
  //   this.page = 1;
  //   this.length = Math.ceil(res.data / 9);
  //   const response = await httpClient.get(`articles?${query}`);
  //   this.blogItems = response.data;
  // }

  @Watch('page', { deep: true })
  async onPageChange() {
    await this.loadPosts();
    window.scrollTo(0, 150);
  }

  async loadPosts() {
    this.loading = true;
    if (this.page === undefined || this.page === null) {
      this.page = 1;
    }
    httpClient.get(`articles?pagination[page]=${this.page}&pagination[pageSize]=9&sort=id:desc&populate=deep`)
      .then((response) => {
        this.length = Math.ceil(response.data.meta.pagination.total / 9);
        this.blogItems = response.data.data.map((blogItem) => {
          // eslint-disable-next-line no-param-reassign
          blogItem = { id: blogItem.id, ...blogItem.attributes };
          if (blogItem.Picture.data === null || blogItem.Picture.data === undefined) {
            // eslint-disable-next-line no-param-reassign
            blogItem.Picture = {
              url: '',
            };
          } else {
            // eslint-disable-next-line no-param-reassign
            blogItem.Picture = { id: blogItem.Picture.data.id, ...blogItem.Picture.data.attributes };
          }
          if (blogItem.Title === null || blogItem.Title === undefined) {
            // eslint-disable-next-line no-param-reassign
            blogItem.Title = '';
          }
          if (blogItem.Slug === null || blogItem.Slug === undefined) {
            // eslint-disable-next-line no-param-reassign
            blogItem.Slug = '#';
          }
          if (blogItem.shortDescription === null || blogItem.shortDescription === undefined) {
            // eslint-disable-next-line no-param-reassign
            blogItem.shortDescription = '';
          }
          if (blogItem.updatedAt === null || blogItem.updatedAt === undefined) {
            // eslint-disable-next-line no-param-reassign
            blogItem.updatedAt = blogItem.publishedAt;
          }
          this.loading = false;
          return blogItem;
        });
      });
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/styles/sass/general/include-media";
.top_box {
  width: 100%;
  background-color: #003B23;
  border-radius: 0px 0px 340px 340px;
  height: 291px;
  padding-left: 35%;
  padding-right: 35%;
  @include media(">phone", "<tablet") {
    border-radius: 0px 0px 170px 170px;
    padding-left: 5%;
    padding-right: 5%;
    height: 231px;
  }
}
</style>
