<template>
  <v-card class="mx-auto my-12 rounded-xl" max-width="460">
    <v-img height="250" :src="imageUrl"></v-img>

    <v-card-title style="word-break: break-word">
      <a class="card-title" :href="'/blog/' + slug">{{ title }}</a>
    </v-card-title>

    <v-card-text>
      <div class="card-description">
        {{ shortDescription }}
      </div>
    </v-card-text>

    <v-card-text>
      <div class="card-date">{{ publishedAt }}</div>
    </v-card-text>
    <!--    <v-divider class="mx-4"></v-divider>-->
    <!--    <v-card-actions>-->
    <!--      <v-btn-->
    <!--        color="deep-purple lighten-2"-->
    <!--        text-->
    <!--        @click="reserve"-->
    <!--      >-->
    <!--        Reserve-->
    <!--      </v-btn>-->
    <!--    </v-card-actions>-->
  </v-card>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class BlogItem extends Vue {
  @Prop() imageUrl;

  @Prop() title;

  @Prop() slug;

  @Prop() shortDescription;

  @Prop() publishedAt;
}
</script>

<style scoped>
.card-title {
  text-decoration: none;
  color: black;
  font-family: Rubik;
  font-size: 22px;
  font-width: 500;
}
.card-description {
  text-decoration: none;
  color: #2a2a2c;
  font-family: Rubik;
  font-size: 16px;
  font-width: 400;
}

.card-date {
  text-decoration: none;
  color: black;
  font-family: Rubik;
  font-size: 16px;
  font-width: 400;
  color: #55575e;
}
</style>
