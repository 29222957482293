var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "mx-0 mx-md-auto col-12 col-md-11 pl-0 pr-0 pt-10" },
    [
      _c(
        "v-row",
        [
          _c("v-col", { staticClass: "col-md-12" }, [
            _c(
              "div",
              {
                staticClass:
                  "top_box d-flex flex-column justify-center text-center",
              },
              [
                _c(
                  "h1",
                  {
                    staticStyle: {
                      "font-family": "Merriweather !important",
                      color: "white",
                    },
                  },
                  [_vm._v(" Car Advice, Articles, DIY Auto Repair and More ")]
                ),
              ]
            ),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c("v-progress-linear", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            attrs: { size: 70, width: 10, color: "#12945f", indeterminate: "" },
          }),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mr-5 ml-5 mt-0 mr-md-15 pl-md-15 pr-md-15" },
        _vm._l(_vm.blogItems, function (blogItem) {
          return _c(
            "v-col",
            { key: blogItem.id, staticClass: "col-md-4" },
            [
              _c("blog-item", {
                attrs: {
                  "image-url": _vm.baseUrl + blogItem.Picture.url,
                  title: blogItem.Title,
                  slug: blogItem.Slug,
                  "short-description": blogItem.shortDescription,
                  "published-at": _vm
                    .moment(
                      new Date(blogItem.publishedAt).getFullYear() > 2000
                        ? blogItem.publishedAt
                        : blogItem.updatedAt
                    )
                    .format("ll"),
                },
              }),
            ],
            1
          )
        }),
        1
      ),
      _c(
        "v-row",
        { staticClass: "mb-15", attrs: { justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "8" } },
            [
              _c("v-pagination", {
                attrs: { length: _vm.length, "total-visible": 7, circle: "" },
                model: {
                  value: _vm.page,
                  callback: function ($$v) {
                    _vm.page = $$v
                  },
                  expression: "page",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }